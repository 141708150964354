import React, { Component } from 'react';
import { Player } from 'video-react'

const BASE_URL = process.env.REACT_APP_BASE_URL || '';

const sources = {
  testVideo: `${BASE_URL}/video.mp4`,
  '/water': `${BASE_URL}/videos/WasserUndNitrate_Loop_v2_2.mp4`,
  '/gas/hydrogen': `${BASE_URL}/videos/gas_1_Erneuerbare_v5_2024.mp4`,
  '/gas/hydrogen-production': `${BASE_URL}/videos/gas_2_Wasserstoff_v5_2024.mp4`,
  '/gas/hydrogen-transport': `${BASE_URL}/videos/gas_3_Transport_v6_2024.mp4`,
  '/gas/hydrogen-application': `${BASE_URL}/videos/gas_4_Anwendung_v7_2024.mp4`,
  '/water/nitrates': `${BASE_URL}/videos/nitrate_1_Wasserversorgung_v4.mp4`,
  '/water/nitrates/2': `${BASE_URL}/videos/nitrate_2_Reinigung_A_v5.mp4`,
  '/water/nitrates/3': `${BASE_URL}/videos/nitrate_3_Reinigung_B_v3_1.mp4`,
  '/water/climate-change': `${BASE_URL}/videos/water_1_Sun_v2_2024.mp4`,
  '/water/climate-change/2': `${BASE_URL}/videos/water_2_Rain_v3_2024.mp4`,
  '/water/climate-change/3': `${BASE_URL}/videos/water_3_Demographic_v5_2024.mp4`,
  '/water/climate-change/4': `${BASE_URL}/videos/water_4_Wassernetz_v6_2024.mp4`,
  '/water/climate-change/5': `${BASE_URL}/videos/water_5_Hand_v6_2024.mp4`
};

const placeholders = {
  '/gas/hydrogen': `${BASE_URL}/posters/gas_1_Erneuerbare_v5_2024.jpg`,
  '/gas/hydrogen-production': `${BASE_URL}/posters/gas_2_Wasserstoff_v5_2024.jpg`,
  '/gas/hydrogen-transport': `${BASE_URL}/posters/gas_3_Transport_v6_2024.jpg`,
  '/gas/hydrogen-application': `${BASE_URL}/posters/gas_4_Anwendung_v7_2024.jpg`,
  '/water/nitrates': `${BASE_URL}/posters/nitrate_1_Wasserversorgung_v4.jpg`,
  '/water/nitrates/2': `${BASE_URL}/posters/nitrate_2_Reinigung_A_v5.jpg`,
  '/water/nitrates/3': `${BASE_URL}/posters/nitrate_3_Reinigung_B_v3_1.jpg`,
  '/water/climate-change': `${BASE_URL}/posters/water_1_Sun_v2_2024.jpg`,
  '/water/climate-change/2': `${BASE_URL}/posters/water_2_Rain_v3_2024.jpg`,
  '/water/climate-change/3': `${BASE_URL}/posters/water_3_Demographic_v5_2024.jpg`,
  '/water/climate-change/4': `${BASE_URL}/posters/water_4_Wassernetz_v6_2024.jpg`,
  '/water/climate-change/5': `${BASE_URL}/posters/water_5_Hand_v6_2024.jpg`
};

const loops = {
  '/': true,
  '/water': true,
  '/gas/hydrogen': true,
  '/gas/hydrogen-production': true,
  '/gas/hydrogen-transport': true,
  '/gas/hydrogen-application': true,
  '/water/nitrates': true,
  '/water/nitrates/2': true,
  '/water/climate-change': true,
  '/water/climate-change/2': true,
  '/water/climate-change/3': true,
  '/water/climate-change/4': true,
  '/water/climate-change/5': true
};

export default class VideoPlane extends Component {
  
  constructor(props, context) {
    super(props, context);

    this.state = {
      source: sources.testVideo,
      flag: 'active',
      loop: true
    };

    this.play = this.play.bind(this);
    this.pause = this.pause.bind(this);
    this.load = this.load.bind(this);
    this.seek = this.seek.bind(this);
    this.setMuted = this.setMuted.bind(this);
    this.location = props.location;
    this.posterVisible = props.posterVisible;
    this.playVideo = props.playVideo;
    this.setPlayVideo = props.setPlayVideo;
  }

    componentDidUpdate(prevProps) {
    if (prevProps.posterVisible !== this.props.posterVisible) {
      console.log('cDU / prevProps.posterVisible: ' + prevProps.posterVisible + ' props.posterVisible: ' + this.props.posterVisible + ' pathname: ' + sources[this.props.location.pathname]);
      if (prevProps.posterVisible === true && this.props.posterVisible === false) {
        // Unload video
      } else {
        console.log('B / else: pause');
        this.player.pause();
      }
    }
  
    if (prevProps.location.pathname !== this.props.location.pathname) {
      console.log(this.props.location.pathname);
      console.log('test: ', sources[this.props.location.pathname], this.state.source, ' 2: ', prevProps.location.pathname, this.props.location.pathname + ' posterVisible: ' + this.props.posterVisible);
      this.player.pause();
      this.setState({
        flag: 'inactive',
        placeholder: placeholders[this.props.location.pathname],
        loop: loops[this.props.location.pathname]
      });
  
      if (this.props.location.pathname !== '/') {
        this.interval = setTimeout(() => {
          this.setState({
            source: null // Reset source to force reload
          }, () => {
            this.setState({
              source: sources[this.props.location.pathname]
            });
            this.player.load();
            this.player.play();
            this.setState({
              flag: 'active'
            });
          });
        }, 1000);
      }
    } else {
      if (this.props.playVideo === true) {
        // Handle play video
      }
    }
  }

  componentDidMount() {
    // subscribe state change
    this.player.subscribeToStateChange(this.handleStateChange.bind(this));

  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  setMuted(muted) {
    return () => {
      this.player.muted = muted;
    };
  }

  handleStateChange(state) {
    // copy player state to this component's state
    // state.paused = false;
    // state.loop = true;
    // state.playing = true;

    this.setState({
      player: state
    });

    // console.log('hSC ',state.currentTime);

    if(state.currentTime === state.duration && state.loop == false){
      console.log("end of file."+ state.loop);
      console.log(this.context);
      
    }
  }

  play() {
    this.player.play();
  }

  pause() {
    this.player.pause();
  }

  load() {
    this.player.load();
  }

  seek(seconds) {
    return () => {
      this.player.seek(seconds);
    };
  }

  changeSource(name) {
    // console.log('changeSource '+ sources[name])
    //console.log('source: '+this.state.source);

    return () => {
      this.setState({
        source: sources[name]
      });
      this.player.load();
      console.log('done loading.')
      this.player.play();

      var mouseClickEvents = ['mousedown', 'click', 'mouseup'];
      function simulateMouseClick(element){
        mouseClickEvents.forEach(mouseEventType =>
          element.dispatchEvent(
            new MouseEvent(mouseEventType, {
                view: window,
                bubbles: true,
                cancelable: true,
                buttons: 1
            })
          )
        );
      }
      
      var element2 = document.querySelector('#dvgw-play');
      simulateMouseClick(element2);

    };
  }

  updateAndNotify = () => {
    console.log("pdt n ntf")
  }

  render() {
    return (
      <div className={'player-parent ' + this.state.flag}>
        <Player
          ref={player => {
            this.player = player;
          }}
          controls={false}
          //poster={this.state.placeholder}
          loop={this.state.loop}
        >
          <source src={this.state.source} />

        </Player>
        <div className="player-buttons">
        <div className="py-3">
        {this.state.source}
          <button onClick={this.play} className="mr-3" id="dvgw-play">
            play()
          </button>
          {/*<button onClick={this.pause} className="mr-3">
            pause()
          </button>
          <button onClick={this.load} className="mr-3">
            load()
          </button> */}
        </div>
        <div className="pb-3">
          <button onClick={this.changeSource('/')} className="mr-3" id="dvgw-screensaver">
            home
          </button>
          {/* <button onClick={this.changeSource('one')} className="mr-3">
            one
          </button>           */}
        </div>
        {/* <div>State</div>
        <pre>
          <p className="language-json">
          {JSON.stringify(this.state.source, null, 2)}
            {JSON.stringify(this.state.player, null, 2)}
          </p>
        </pre> */}
        </div>
        <div id="player-curtain">&nbsp;</div>
        
      </div>
    );
  }
}